import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Text, Box } from "insites-ui"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <section className="text-page-section">
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-lg-12 px-0"}>
            <Heading>Terms and Conditions</Heading>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="0.5rem 2rem"
            >
              <Heading as="h4" size="sm">
                Introduction
              </Heading>
              <Text>
                The following Terms and Conditions will apply to any use of the
                Website and related software or applications (collectively known
                as &quot;the Website&quot;) by customers or users of Investment
                Property Tracker (IPT) entities. Users also acknowledge that
                additional terms and conditions may apply to individual pages,
                transactions and / or services by the Website. By using the
                Website, users and customers acknowledge that they have read,
                understood and agree to the terms and conditions that bound
                usage. If users and customers do not agree to the terms and
                conditions, please do not use or browse the Website in any
                capacity. These terms and conditions notice contain legal
                disclaimers and/or other provisions that restrict and limit the
                legal liability of IPT entities to users and customers. The
                Website cannot be used for any unlawful purpose.{" "}
              </Text>
              <Text>
                IPT may provide information, including Personal Information of
                users, with other IPT related companies, affiliates, licensors
                and licensees (collectively referred to as &ldquo;the IPT
                Entities&rdquo;). IPT Entities provide the Website for customers
                to provide their data to IPT Entities which may be retained by
                IPT Entities for the purposes including (but not limited to)
                tracking data of users.{" "}
              </Text>
              <Text>
                For the purposes of this Terms and Conditions, &quot;data&quot;
                refers to any input or data provided by users to the Website and
                or applications operated by IPT Entities.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Revisions to Terms and Conditions{" "}
              </Heading>
              <Text>
                These Terms and Conditions may be revised or amended without
                notice, at any time by posting revised or amended terms and
                conditions. Any revised or amended terms and conditions are
                effective immediately and by using or browsing the Website, you
                agree to the revised Terms and Conditions in full. If, for any
                reason, any part of the terms and conditions is deemed to be
                unenforceable, you agree that the remaining portion of the Terms
                and Conditions remains in full force and effect.{" "}
              </Text>
              <Text>
                If you do not agree with revised terms and conditions, please do
                not use or browse the Website in any capacity and no liability
                or compensation can or will be provided.{" "}
              </Text>
              <Text>
                Whilst IPT and IPT entities will make reasonable efforts to
                provide timely and accurate information about the services and
                products offered by IPT on the Website, IPT and IPT entities
                cannot guarantee that information provided is correct, up to
                date and / or all relevant information is available.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Use restrictions, Website content and ownership{" "}
              </Heading>
              <Text>
                Unless otherwise indicated, the content, material, code,
                designs, images, audio clips, other software, text, graphics,
                logo and icons (known as &quot;the Contents&quot;) is owned or
                controlled by IPT and IPT entities and protected under copyright
                laws of Australia and other countries. The Website may not
                display all contents owned or controlled by IPT entities.
                Contents not displayed are also subject to copyright laws of
                Australia and other countries. All rights not expressly granted
                are reserved.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Links to Third Party Websites{" "}
              </Heading>
              <Text>
                Information from or hyperlinks from third party websites may be
                contained in the Website. This information and websites are not
                owned or controlled by IPT entities and therefore is not
                responsible for their content. Hyperlinks and information is
                merely provided for convenience and therefore the inclusion of
                information and hyperlinks from third parties does not
                constitute endorsement of the third party or its contents. You
                rely on this information at your own risk. Personal data
                provided to these third party websites is not covered
                IPT&lsquo;s privacy policy and therefore IPT and its entities
                have no legal liability as users choose to do so at their own
                discretion. You are encouraged to read, agree and understand the
                privacy policy and terms and conditions of third party websites.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Personal, non-commercial use{" "}
              </Heading>
              <Text>
                You are only allowed use the Website for personal purposes (and
                not commercial purposes), which is determined at the discretion
                of IPT at any time. A user cannot have more than 20 properties
                uploaded to the website and data cannot be uploaded which does
                not constitute reasonable data for an individual property
                investor with up to 20 investment properties. A user or customer
                must not exploit any materials, parts, contents or aspects of
                the Website for commercial or other purposes that are not
                permitted within the Terms and Conditions or as determined by
                IPT with its absolute discretion.{" "}
              </Text>
              <Text>
                IPT&lsquo;s privacy policy should be reviewed, read, agreed to
                and understood prior the use or browsing of the Website. Users
                and customers are bound by the privacy policy and to use of
                personal information and any other submissions to IPT entities
                in accordance with the privacy policy, unless other consents or
                notices are required by law.{" "}
              </Text>
              <Text>
                You also agree to not use the Website in any unlawful way, or
                that may deemed inappropriate, unlawful or prohibited by IPT.
                You agree to not cause damage to the Website, or violate any
                international, national, federal, state or local or any other
                laws applicable. You also agree to not infringe on the rights of
                any third parties.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Prohibited conduct{" "}
              </Heading>
              <Text>
                You are not authorised to reproduce, frame, download, store,
                show or play in public, adapt change, create a derivative or the
                Website or any other content owned by IPT without prior written
                permission. You may not reproduce, modify, sell, repost or
                convert any materials from the Website or IPT that is not
                aligned with the terms and conditions.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Licence to use{" "}
              </Heading>
              <Text>
                By using this Website, uploading information or inputting any
                data or material, you agree to grant the Supplier a irrevocable,
                non-exclusive, royalty-free, full-paid and perpetual license to
                use the material, information, ideas that you transmit and
                authorise without restrictions and payments or any other
                consideration, permission or notification to you or a third
                party to use the material in any way at the discretion of IPT
                Entities. You also authorise the waiving of any &quot;moral
                rights&quot; (if any apply) relating to copyright materials that
                you provide to IPT Entities.{" "}
              </Text>

              <Heading as="h4" size="sm">
                User conduct{" "}
              </Heading>
              <Text>
                You are not allowed to upload, transmit, post or make available
                any material to the Website that may infringe or violate on the
                rights of others that is unlawful, abusive, defamatory, invades
                privacy, profane, vulgar, obscene or causes any harm,
                inconvenience to any entity or person.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Indemnity{" "}
              </Heading>
              <Text>
                All users and customers of the Website must agree to indemnify
                and keep IPT and Entities indemnified against any claim, damage,
                loss, expense, cost, liability, injury against IPT entities in
                relation any of their operations, including the Website or
                breaching any rights of third parties.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Provision of Services by the Supplier{" "}
              </Heading>
              <Text>
                Reports can be reproduced only for personal purposes.{" "}
              </Text>
              <Text>
                Access to the website and contents is via a login (username and
                password). You must agree to not allow the login to be used any
                other person, entity or computer or allow any one who is not a
                registered to gain access to any part of the Website. From time
                to time, IPT may provide discounts for its products and services
                at its own discretion. It is the users responsibility to cancel
                the subscription to a product or service. This will result in
                the forfeiture of any money paid to IPT for the provision of any
                service.{" "}
              </Text>
              <Text>
                You agree that no information provided to an individual customer
                or user is provided on an exclusive basis. You also agree that
                IPT and IPT entities may provide certain information and leads
                on to third parties for free or for consideration.{" "}
              </Text>
              <Text>
                IPT will endeavour to keep the IPT Website secure, backed up,
                protected with encryption and firewalls and any other measure
                applicable but note that no system on the internet is ever 100%
                secure. Prices for products and services may change at any time,
                whilst effort to keep information accurate and reliable will be
                ensured, IPT is not responsible for any errors, omissions and
                reserves the right to change prices, and reject your offer for
                any reason to use or pay for any product or service.{" "}
              </Text>
              <Text>
                The user and customer agrees that any content or Website
                material does not constitute professional advice including
                taxation and financial and the completion of a tax return by the
                customer or user.{" "}
              </Text>

              <Heading as="h4" size="sm">
                User Responsibilities and Obligations{" "}
              </Heading>
              <Text>
                All prices stated on Website are inclusive of GST unless
                otherwise stated. Prices may change at any time and subject to
                availability. Prices and availability may change at the
                discretion of IPT without notice. We reserve the right to limit
                services, and prohibit services to re-sellers. When IPT services
                are purchased on a subscription basis, payments will be made
                automatically, one month in advance and on a monthly basis.
                Payments will be deducted up to 5 business days before the due
                date. Failure to make a payment on the due date (for any reason)
                may result in account restrictions until the outstanding amounts
                are paid in full. Provision of services may be terminated if the
                user does not pay subscription fees due by the due date.{" "}
              </Text>
              <Text>
                You are agree to provide IPT with your most update to date
                contact and payment details and inform IPT immediately of any
                changes in writing.{" "}
              </Text>
              <Text>
                The user agrees to that failure of entering accurate data and
                complete information may adversely impact the usage of the
                Website. IPT and IPT entities are not responsible for providing
                and maintaining a functioning computer, back up services,
                firewalls, secure internet connections for the use of the
                Website.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Disclaimer of warranty and limitation of liability{" "}
              </Heading>
              <Text>
                Use or browsing of this website and its content is at the users
                risk and is on a &lsquo;as is&lsquo; basis. IPT entities make no
                warranty, liability or responsibility to the accuracy,
                completeness, reliability of the information provided or
                contained on the Website, and to the any damage in any nature or
                situate that may result to you or a third party in connection to
                IPT entities. To the extent permitted by law, no employee,
                director or founder of IPT is liable or responsible in any way
                for errors, omissions contained in the Website and expressly
                disclaim any and all warranties for any purpose.{" "}
              </Text>
              <Text>
                You agree that any operation, use or browsing of this Website
                will be in Australia only.{" "}
              </Text>
              <Text>
                IPT discloses that it does not currently hold or possess an
                Australian Financial Services Licence (AFSL) and does not
                provide any financial, legal, taxation or strategic advice. The
                use or browsing of the Website and content is for entertainment
                purposes only and does not take into account your individual
                objectives. Information and advice offered is general in nature.
                You must obtain appropriate professional advice before making
                investment decisions. IPT discloses and you agree that the
                Website is not a substitute for professional legal, taxation,
                financial, strategic or other relevant professional advice.{" "}
              </Text>

              <Heading as="h4" size="sm">
                IP Protocol Addresses and Web Logs{" "}
              </Heading>
              <Text>
                The Website&lsquo;s servers, may from time to time collect
                Internet Protocol (IP) addresses and log files of users and
                customers. Collecting IP address is standard practice and is
                done automatically by many websites.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Marketing Material{" "}
              </Heading>
              <Text>
                By registering on the Website, you have provided IPT Entities
                with consent to receive commercial messages by email or any
                other media that IPT entities consider relevant to the sale,
                rental, purchase or other dealings with residential property. If
                you no longer wish to receive such commercial messages, you can
                un-subscribe by clicking &lsquo;Unsubscribe&lsquo; at the bottom
                of the commercial messages you have received.{" "}
              </Text>

              <Heading as="h4" size="sm">
                No Assignment and Waiver{" "}
              </Heading>
              <Text>
                You agree that you may not under any circumstance, transfer or
                assign in any way, any of your rights and obligations under the
                terms and conditions. Any such transfer or assignment shall be
                null and void.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Applicable Law, Jurisdiction and Severability{" "}
              </Heading>
              <Text>
                You agree that use and browsing of the Website, the terms and
                conditions and privacy policy are governed by the laws of
                Australia and any legal proceedings and legal actions pertaining
                to your use of the Website and its contents or compliance with
                these terms and conditions and privacy policy will be in the
                jurisdiction of the courts in New South Wales, Australia.{" "}
              </Text>
              <Text>
                You also agree to waive any jurisdictional and venue defences
                that might available to you.{" "}
              </Text>
              <Text>
                You are that headings used through the Terms and Conditions and
                privacy policy are for reference only and should be ignored when
                understanding or interpreting the documents.{" "}
              </Text>

              <Heading as="h4" size="sm">
                Complete Understanding{" "}
              </Heading>
              <Text>
                You agree with and warrant IPT that you constitute a complete
                understanding of the Terms and conditions and privacy policy,
                with respect to your use of the Website, including all products
                and services offered through the Website.{" "}
              </Text>
              <Text>
                For more information, you can contact us via{" "}
                <a
                  href="mailto:admin@investmentpropertytracker.com.au"
                  target="_top"
                >
                  admin@investmentpropertytracker.com.au
                </a>
                .
              </Text>
            </Box>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default TermsPage
